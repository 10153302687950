import { useState, useEffect } from 'react';
import { Modal } from 'components/Modal';
import { SpinnerCircular } from 'spinners-react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import cx from 'classnames'

import TextField from './TextField'
import Textarea from './Textarea'

// import { MessageContext } from 'App'
// import { getPeopleList, getBook, updateBook, deleteBook, createTag, updateTag, deleteTag } from 'api/book'
import { getBook, updateBook, deleteBook } from 'api/book'
// import { formatInputDate, peopleToSelectOptions } from 'helpers/utils'

const Container = styled.div`
  label {
    margin: 0;
  }

  .Tools {
    display: flex;

    &-Item {
      flex-grow: 1;
    }
  }

  .TagsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    row-gap: 0;
  }

  .Tags {
    display: flex;

    button {
      padding: 0;
      border: none;
      background: none;
      color: #007bff;
    }

    .Actions {
      display: flex;
      gap: 4px;
    }

    .Tag__Selected {
      color: #0a0;
      transform: scale(1.5);
    }
  }

  .image {
    #img-mapper {
      min-width: 100%;
    }
  }

  .img-mapper-img {
    width: 100%;
  }

  .Button {
    border: 1px solid #c0c0c0;
    padding: 12px;
    width: 100%;
    background-color: #007dff;
    color: #fefefe;
  }
`

export const BookModal = ({ id, setId, setBooks }) => {
  const navigate = useNavigate()
  // const { showSuccess, showError } = useContext(MessageContext)
  const [searchParams, _] = useSearchParams()
  // const [peopleList, setPeopleList] = useState([])
  const [book, setBook] = useState(null)
  const [coords, setCoords] = useState(null)
  const [ratio, setRatio] = useState(null)
  const [selectedTagIndex, setSelectedTagIndex] = useState(null)

  // const peopleOptions = useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList])

  // const statusOptions = [
  //   { label: 'Неапрацаваныя', value: 'draft' },
  //   { label: 'Адкладзеныя', value: 'pending' },
  //   { label: 'Апрацаваныя', value: 'completed' },
  // ]

  const onRequestClose = () => {
    setId(null)
  }

  const onChangeByName = (name) => (e) => setBook({ ...book, [name]: e.value ?? (e.target.type == 'checkbox' ? e.target.checked : e.target.value)})

  useEffect(() => { getBook(id).then(setBook) }, [id])
  // useEffect(() => { getPeopleList().then(setPeopleList) }, [])

  // const onMouseAreaTrigger = (index, event) => () => document.getElementsByTagName('area')[index]?.dispatchEvent(event)

  // useEffect(() => {
  //   if (coords && coords[0] === coords[2]) {
  //     setSelectedTagIndex(null)
  //   }
  // }, [coords])

  // const onClickImageMapper = ({ href }, index, event) => {
  //   navigate(href)
  // }

  // const onTagDelete = (tagId) => {
  //   const tag = book.tags.find(({ id }) => id === tagId)

  //   if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць тэг: ${tag?.title}?`)) {
  //     deleteTag(tagId).then((tag) => {
  //       showSuccess('Тэг пасьпяхова выдалены')
  //       setBook({ ...book, tags: [  ...book?.tags.filter(({ id }) => id !== tag?.id) ]})
  //     }).catch((respose) => {
  //       showError()
  //     })
  //   }
  // }

  const onBookDelete = () => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць кнігу?`)) {
      deleteBook(book.id).then((response) => {
        // showSuccess('Фотаздымак пасьпяхова выдалены')

        setBooks((books) => books.filter(({ id: bookId }) => bookId != id))
        setId(null)
      }).catch((respose) => {
        // showError()
      })
    }
  }

  // const onTagCreate = ({ value }) => {
  //   const params = {
  //     tag: {
  //       book_id: book?.id,
  //       person_id: value,
  //       data: { coords }
  //     }
  //   }

  //   createTag(params).then((tag) => {
  //     showSuccess('Тэг пасьпяхова дададзены')
  //     setBook({ ...book, tags: [...book?.tags, tag.attributes] })
  //   }).catch((respose) => {
  //     showError()
  //   })
  // }

  const onSumbit = () => {
    const params = { title: book?.title, author: book?.author, year: book?.year, description: book?.description, book_type: book?.book_type, is_virtual: book?.is_virtual }

    updateBook(book?.id, params).then((book) => {
      // showSuccess('Кніга пасьпяхова абноўлена')

      // books.findIndex(({ id: bookId }) => bookId == id)

      setBooks((books) => {
        const index = books.findIndex(({ id: bookId }) => bookId == id)

        return [
          ...books.slice(0, index),
          {
            ...books[index],
            attributes: {
              ...books[index].attributes,
              title: book?.title,
              author: book?.author,
              year: book?.year,
              book_type: book?.book_type,
              is_virtual: book?.is_virtual,
            }
          },
          ...books.slice(index + 1)
        ]
      })

      setId(null)
    }).catch((respose) => {
      // showError()
    })
  }

  return <Modal
    isOpen={id ? true : false}
    contentLabel='Рэдагаваньне фотаздымка'
    onRequestClose={onRequestClose}
  >
    {
      book ? (
        <Container>
          {/* TODO: Add buttons */}
          <div className="Tools">
            <div></div>
            {
              <button className="btn Tools-Item" rel="nofollow" onClick={onBookDelete}>
                <i className="fa fa-trash"></i>
              </button>
            }
          </div>
          <hr/>
          {/* <div className='TagsWrapper'>
            {
              book?.tags.map(({ id, title, person_id }, index) => {
                return (
                  <span
                    className='Tags'
                    key={id}
                    onMouseEnter={onMouseAreaTrigger(index, MOUSEOVER)}
                    onMouseLeave={onMouseAreaTrigger(index, MOUSEOUT)}
                  >
                    [{
                      <span className='Actions'>
                        <Link to={`/people/${person_id}`}>{title}</Link>
                        <Link className={cx({ 'Tag__Selected': selectedTagIndex === index })} onClick={(e) => toggleSelectedTagIndex(e, index)}><i className="fa fa-pencil"></i></Link>
                        <button href={`/tags/${id}`} onClick={() => onTagDelete(id)}><i className="fa fa-trash"></i></button>
                      </span>
                    }]
                  </span>
                )
              })
            }
          </div> */}
          <br/>
          {/* <Select label={'Дадаць тэг'} emptyLabel value='' onChange={onTagCreate} options={peopleOptions}/> */}
          <TextField label={'Назва'} value={book?.title || ''} onChange={onChangeByName('title')}/>
          <TextField label={'Аўтар'} value={book?.author || ''} onChange={onChangeByName('author')}/>
          <TextField label={'Дата'} value={book?.year || ''} onChange={onChangeByName('year')}/>
          <Textarea label={'Апісаньне'} value={book?.description || ''} onChange={onChangeByName('description')}/>
          <select if='periodic' value={book?.book_type} onChange={onChangeByName('book_type')}>
            <option value='periodic'>Перыядычныя</option>
            <option value='non_periodic'>Не перыядычныя</option>
          </select>
          <div>
            <label htmlFor='is_virtual' className="form-control-label string optional">
              Віртуальная
            </label>
            <input id='is_virtual' type='checkbox' checked={String(book?.is_virtual) == 'true'} onChange={onChangeByName('is_virtual')}/>
          </div>
          {/* <Select label={'Статус'} value={statusOptions.find(({ value }) => value == book?.status)} options={statusOptions} onChange={onChangeByName('status')} menuPlacement='auto'/> */}
          <hr/>
          <button className="Button btn btn btn-default" onClick={onSumbit}>Захаваць</button>
        </Container>
      ) : (
        <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
      )
    }
  </Modal>
}
