import { useState, useEffect } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import './App.scss'
import Cookies from 'js-cookie';

import { getBooks } from 'api/book'
import styled from "styled-components"
import { BookModal } from 'components/BookModal';

import 'font-awesome/css/font-awesome.css'

const token = Cookies.get('csrf_token');

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Books {
    flex-wrap: wrap;
    gap: 32px;
    display: flex;
  }

  .Search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    gap: 4px;

    > div {
      display: flex;
      justify-content: center;
      gap: 8px;
    }

    .Search-Filter {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

const Book = styled('div')`
  text-align: center;
  width: 160px;
  border: 1px solid #000;
  padding: 8px;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .Image {
    position: relative;
    padding-top: 150%;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      // object-fit: cover
    }
  }

  .Description {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .Title {
      font-weight: bold;
    }
  }
`

const App = (props) => {
  const [books, setBooks] = useState([])
  const [filteredBooks, setFilteredBooks] = useState([])
  const [selectedBookId, setSelectedBookId] = useState(null)
  const [search, setSearch] = useState([])
  const [filter, setFilter] = useState({ withoutTitle: false, withoutVirtual: false, bookType: '', isInvert: false })

  useEffect(async () => {
    const books = await getBooks()

    setBooks(books)
  }, [])

  useEffect(() => {
    if (books) {
      setFilteredBooks(books.filter(({ attributes: { title, author, year, book_type, is_virtual }}) => {
        const result = ([title, author, year].join(' ').includes(search) &&
          (filter['withoutTitle'] ? !title : true) &&
          (filter['withoutVirtual'] ? !is_virtual : true) &&
          (filter['bookType'] ? book_type == filter['bookType'] : true)
        )

        return filter['isInvert'] ? !result : result
      }))
    }
  }, [books, search, filter])

  return token && (
    <BrowserRouter>
      <Container>
        <div className='Search'>
          <div className='Search-Input'>
            <input value={search} onChange={(e) => setSearch(e.target.value)}></input>
          </div>
          <div className='Search-Filter'>
            <input id='title' type='checkbox' value={filter['withoutTitle']} onChange={(e) => setFilter({ ...filter, withoutTitle: e.target.checked })}/>
            <label htmlFor='title'>Бяз назваў</label>
            <input id='virtual' type='checkbox' value={filter['withoutVirtual']} onChange={(e) => setFilter({ ...filter, withoutVirtual: e.target.checked })}/>
            <label htmlFor='virtual'>Бяз віртуальных</label>
            <select if='periodic' value={filter['bookType']} onChange={(e) => setFilter({ ...filter, bookType: e.target.value })}>
              <option value=''>Усе</option>
              <option value='periodic'>Перыядычныя</option>
              <option value='non_periodic'>Не перыядычныя</option>
            </select>
            <input id='is_invert' type='checkbox' value={filter['is_invert']} onChange={(e) => setFilter({ ...filter, isInvert: e.target.checked })}/>
            <label htmlFor='is_invert'>Інвертаваць</label>
          </div>
          <div className='Search-Information'>
            Колькасьць: {filteredBooks.length}
          </div>
        </div>
        <div className='Books'>
          {
            filteredBooks.map(({ id, attributes: { title, author, year, file_url, cover_url }}) => {
              return (
                <Book key={id}>
                  <div>
                    <div className="Image">
                      <img src={cover_url}/>
                    </div>
                    <div className="Description">
                      <div className="Title">{title}</div>
                      <div className="Author">{author}</div>
                      {
                        year && (
                          <div className="Year">{year}</div>
                        )
                      }
                      <div className="id" onClick={() => setSelectedBookId(id)}>[{id}]</div>
                      {
                        file_url && (
                          <a href={file_url}>Чытаць</a>
                        )
                      }
                    </div>
                  </div>
                </Book>
              )
            })
          }
        </div>
        {
          selectedBookId && <BookModal id={selectedBookId} setId={setSelectedBookId} setBooks={setBooks}></BookModal>
        }
      </Container>
    </BrowserRouter>
  )
}

export default App
